import { UserRole } from "@/lib/types/user";
import { se } from "date-fns/locale";
import { NextApiRequest, NextApiResponse } from "next";
import { NextAuthOptions, Profile, TokenSet } from "next-auth";
import { JWT } from "next-auth/jwt";
import NextAuth from "next-auth/next";
import AzureADB2C from "next-auth/providers/azure-ad-b2c";

const B2C_TENANT = process.env.NEXT_PUBLIC_AD_B2C_TENANT_NAME as string;
const B2C_USERFLOW = process.env.NEXT_PUBLIC_AD_B2C_USER_FLOW as string;
const B2C_CLIENT_ID = process.env.NEXT_PUBLIC_AD_B2C_CLIENT_ID as string;
const B2C_CLIENT_SECRET = process.env.NEXT_PUBLIC_AD_B2C_CLIENT_SECRET as string;
const B2C_SCOPE = process.env.NEXT_PUBLIC_AD_B2C_SCOPE as string;
const isDEV = process.env.NEXT_PUBLIC_ENV as string;
const B2C_REFRESH_TOKEN_URL = process.env.NEXT_PUBLIC_AD_B2C_REFRESH_TOKEN_URL as string;

const authOptions: NextAuthOptions = {
  debug: isDEV === "dev",
  providers: [
    AzureADB2C({
      clientId: B2C_CLIENT_ID,
      tenantId: B2C_TENANT,
      primaryUserFlow: B2C_USERFLOW,
      clientSecret: B2C_CLIENT_SECRET,
      authorization: {
        params: {
          scope: B2C_SCOPE,
        },
      },
      checks: ["pkce"],
      client: {
        token_endpoint_auth_method: "none",
      },
    }),
  ],
  session: {
    strategy: "jwt",
    maxAge: 12 * 60 * 60,
  },
  jwt: {
    // maxAge: 60 * 60,
    // maxAge: 5 * 60 - 1,
  },
  callbacks: {
    // the console logs will only be seen on the nextjs server side (you can open up azure live logs to see it)
    // each of these callbacks will run depending on what you are doing
    // see `https://next-auth.js.org/configuration/callbacks`
    async jwt({ token, account, profile }) {
      if (account) {
        return {
          ...token,
          accessToken: account.access_token,
          refreshToken: account.refresh_token,
          tokenExpiresOn: account.expires_on,
          role: (profile as Profile & { jobTitle: UserRole })?.jobTitle ?? "admin",
        } as JWT;
      }
      else if (Date.now() < token.tokenExpiresOn! * 1000) {
        return token;
      }
      else {
        try {
          // if we are here then token probably expired, so attempt to fetch a new one
          // with given refresh token
          console.log(`session may have expired, attempting to get a new token...`);
          const response = await fetch(B2C_REFRESH_TOKEN_URL, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              client_id: B2C_CLIENT_ID,
              scope: B2C_SCOPE,
              grant_type: "refresh_token",
              refresh_token: token.refreshToken as string,
            }),
            method: "POST",
          });
          const newToken: TokenSet = await response.json();
          if (!response.ok) {
            throw newToken;
          }
          console.log(`new token retrieved.`);
          const tokenReturn: JWT = {
            ...token,
            accessToken: newToken.access_token,
            tokenExpiresOn: newToken.expires_on as number,
            // Fall back to old refresh token, but note that
            // many providers may only allow using a refresh token once.
            refreshToken: newToken.refresh_token ?? token.refreshToken,
            exp: newToken.expires_on as number,
            error: undefined,
          };

          return tokenReturn;
        }
        catch (e) {
          console.error(`Error refresh access token`, e);
          return { ...token, error: "RefreshAccessTokenError" as const };
        }
      }
    },
    async session({ session, token }) {
      const sessionToken = {
        ...session,
        token: {
          accessToken: token.accessToken,
          refreshToken: token.refreshToken,
          tokenExpiresOn: token.tokenExpiresOn,
          // role: token.role,
          error: token.error,
        },
        user: {
          ...session.user,
          role: token.role,
        },
      };

      // console.log(`RETURN SESSION TOKEN: `, sessionToken);
      return sessionToken;
    },
  },
  cookies: {
    csrfToken: {
      name: "next-auth.csrf-token",
      options: {
        httpOnly: true,
        sameSite: "none",
        path: "/",
        secure: true,
      },
    },
    pkceCodeVerifier: {
      name: "next-auth.pkce.code_verifier",
      options: {
        httpOnly: true,
        sameSite: "none",
        path: "/",
        secure: true,
      },
    },
  },
} satisfies NextAuthOptions;

// I don't know if the below function actually works or have any use if so
const auth = async (req: NextApiRequest, res: NextApiResponse) => {
  // console.log(`AUTH FUNCTION CONFIG: `, authOptions);
  return await NextAuth(req, res, authOptions);
};

export { authOptions, auth };
